import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '线下就医'
    },
    component: Home
  },
  {
    path: '/offline',
    name: 'index2',
    meta: {
      title: '线下就医'
    },
    component: Home
  },
  {
    path: '/next',
    name: 'next',
    meta: {
      title: '线下就医'
    },
    component: () => import('@/views/Home2.vue')
  },
  {
    path: '/home',
    name: 'HomeView',
    meta: {
      title: '线下就医'
    },
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/healthy',
    name: 'healthy',
    meta: {
      title: '心理咨询'
    },
    component: () => import('@/views/Healthy.vue')
  }

]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})


router.beforeEach((to) =>{

// 路由发生变化修改页面title

  if(to.meta.title) {

    document.title= to.meta.title;

  }
})


export default router
